<template>
  <div class="modal"  :class="{'is-active': active}">
    <div class="modal-background"></div>
    <form
      class="modal-card"
      @submit.prevent="onSubmit"
    >
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">Agregar Administrador</p>
      <button class="delete" aria-label="close" type="reset" @click="close()"></button>
    </header>
    <section class="modal-card-body">
      <div class="columns is-multiline is-centered">
        <div class="column is-11 field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              required
              class="input"
              type="text"
              placeholder="Nombre"
              v-model="firstName"
            >
          </div>
        </div>
        <!-- <div class="column is-11 field">
          <label class="label">Rol</label>
          <div class="select is-fullwidth">
            <select
              v-model="rolId"
              required
            >
              <option
                disabled
                value=""
              >
                Seleccione el rol del administrador
              </option>
              <option
                key="1"
                value="1"
              >
                Administrador
              </option>
            </select>
          </div>
        </div> -->
        <div class="column is-11 field">
          <label class="label">Correo Electrónico</label>
          <div class="control">
            <input
              required
              class="input"
              placeholder="E-mail"
              v-model="email"
              type="email"
            >
          </div>
        </div>
        <div class="column is-11 field">
          <label class="label">Contraseña</label>
          <div class="control">
            <input
              required
              class="input"
              placeholder="********"
              v-model="password"
              type="password"
            >
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        :disabled="loading"
        class="button is-danger"
        type="reset"
        @click="close"
      >
        Cancelar
      </button>
      <button
        class="button button-givu"
        :class="{'is-loading': loading}"
        :disabled="loading"
        type="submit"
      >
        Agregar
      </button>
    </footer>
  </form>
</div>
</template>
<script>
const regexNoLetters = /[^A-Za-zñÑáéíóúÁÉÍÓÚ\s]/g
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      error: false,
      loading: false,
      firstName: null,
      email: null,
      password: null,
      rolId: ''
    }
  },
  watch: {
    firstName (newName) {
      let validText = newName.replace(regexNoLetters, '').replace('  ', ' ')
      if (validText === ' ') validText = ''
      this.firstName = validText
    }
  },
  methods: {
    async onSubmit () {
      this.loading = true
      let data = {
        name: this.firstName,
        lastName: this.lastName,
        secondLastName: this.secondLastName,
        email: this.email,
        password: this.password,
        rolId: this.rolId
      }
      await this.action(data)
      this.loading = false
    },
    isNumber: function (evt) {
      evt = evt || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
  .optional {
    font-size:10px;
  }
</style>
